<template>
  <div class="case">
    <div class="caseTop" id="top">
      <img v-lazy="topBg" alt="" srcset="">
      <div class="text">
        <p>丰富的案例实践经验</p>
        <p>按客户需求定制开发</p>
      </div>
    </div>
    <!-- 会员营销系统 -->
    <div class="caseMember" id="member">
      <div class="caseMember-top">
        <p>会员营销系统</p>
        <p>适用于中小心互联网企业及线下实体店</p>
      </div>
      <div class="caseMember-bottom">
        <div class="caseMember-bottom-left">
          <img v-lazy="memberImg" alt="" srcset="">
        </div>
        <div class="caseMember-bottom-right">
          <div class="caseMember-bottom-right-list" v-for="(item,index) in memberList" :key="index">
            <div class="caseMember-bottom-right-list-top">
              <p>{{ item.title }}</p><img :src="item.img" alt="" srcset="">
            </div>
            <div class="caseMember-bottom-right-list-bottom">
              <p v-if="item.title!='会员体系'&&item.title!='营销工具'">{{ item.content }}</p>
              <p v-if="item.title=='会员体系'||item.title=='营销工具'">{{ item.content.slice(0,19) }}</p>
              <p v-if="item.title=='会员体系'||item.title=='营销工具'">{{ item.content.slice(19) }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 数字化保修系统 -->
    <div class="caseDigitizationr" id="digitization">
      <div class="caseDigitizationr-text" style=" margin-right: 86PX; ">
        <p>数字化报修系统</p>
        <p>适合酒店、物业、工厂等行业无纸化</p>
        <p>报修系统,节约资源，降本增效配合后</p>
        <p>台系统便捷管理报修员、维修员、保</p>
        <p>修单等数据</p>
      </div>
      <img v-lazy="digitizationImg" alt="" srcset="">
    </div>
    <!-- 商城小程序 -->
    <div class="caseShopping" id="shopping">
      <div class="caseShopping-top">
        <p>商城小程序</p>
        <p>适用于大多数线上商城下单场景</p>
      </div>
      <div @mousewheel.prevent="goWheel($event)" class="caseShopping-bottom">
        <el-carousel :interval="5000" ref="swiper" :loop="true" :autoplay="true" indicator-position="none" arrow="never">
          <el-carousel-item v-for="(item,index) in shoppingList" :key="index">
            <div class="caseShopping-bottom-left">
              <img v-lazy="item.img" alt="" srcset="">
            </div>
            <div class="caseShopping-bottom-right">
              <img v-lazy="item.text" alt="" srcset="">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

    </div>
    <!-- 家政上门服务小程序 -->
    <div class="caseDigitizationr">
      <img v-lazy="digitizationImg" alt="" srcset="" style="width:358PX;height:392PX">
      <div class="caseDigitizationr-text" style="margin-left:57PX">
        <p>家政上门服务小程序</p>
      <div class="caseDigitizationr-line"></div>
        <p>适用于上门服务型场景</p>
        <p>集发布任务需求与接单一体化系统</p>
        <p>多角色任意切换便捷操作</p>
      </div>
    </div>
    <!-- 无人值守预约小程序 -->
    <div class="caseUnmanned" id="unmanned">
      <div class="caseUnmanned-top">
        <p>无人值守预约小程序</p>
        <p>适用于各种无人场景，全场景解决方案</p>
      </div>
      <div class="caseUnmanned-bottom">
        <div>
          <p>一键在线预约</p>
          <p>无人值守门店，时间自主可控</p>
          <img v-lazy="unmannedImg1" alt="" srcset="">
        </div>
        <div>
          <p>自动结账</p>
          <p>全程智能化解放人力，自主选择卡券结账</p>
          <img v-lazy="unmannedImg2" alt="" srcset="">
        </div>
      </div>

    </div>

    <!-- 蛋糕预约小程序 -->
    <div class="caseCakeSubscribe" id="cakeSubscribe">
      <div class="caseCakeSubscribe-text" style="margin-right:72PX">
        <p>蛋糕预约小程序</p>
      <div class="caseCakeSubscribe-line"></div>
        <p>适用于个体门店预约下单场景</p>
        <p>快速打造门店私域流量形成线上</p>
        <p>线下引流，二次转化，持续增收</p>
      </div>
      <img v-lazy="cakeSubscribeImg" alt="" srcset="" style="width:511PX;height:393PX">
    </div>

    <!-- 线上云酒馆 -->
    <div class="caseCloudTavern" id="cloudTavern">
      <div class="caseCloudTavern-top">
        <p>线上云酒馆</p>
        <p>适用于同城多站点门店配送场景</p>
      </div>
      <div class="caseCloudTavern-bottom">
        <div class="caseCloudTavern-bottom-left">
          <div class="caseCloudTavern-bottom-left-content" style="margin-top: -50PX;">
            <div class="caseCloudTavern-bottom-content-img">
             <img src="../../assets/Web/case/cloudTavern/tag1.png" alt="">
            </div>
            <div class="caseCloudTavern-bottom-content-content">
              <p>合伙人功能，发展各大高校，<br />
              露营地，轰趴馆，酒店代理，<br />
              帮助拓客</p>
            </div>
          </div>
          <div class="caseCloudTavern-bottom-left-content">
            <div class="caseCloudTavern-bottom-content-img">
              <img src="../../assets/Web/case/cloudTavern/tag2.png" alt="">
            </div>
            <div class="caseCloudTavern-bottom-content-content">
              <p>对接打印机、支持会员、优惠<br />
              券、招商加盟、合伙人分销、<br />
              分类管理等功能</p>
            </div>
          </div>
          <div class="caseCloudTavern-bottom-left-content">
            <div class="caseCloudTavern-bottom-content-img">
              <img src="../../assets/Web/case/cloudTavern/tag3.png" alt="">
            </div>
            <div class="caseCloudTavern-bottom-content-content">
              <p>涵盖站点门店下单配送费、<br />
              站点管理等功能，帮助各<br />
              站点更便捷的运营</p>
            </div>
          </div>
        </div>

        <div class="caseCloudTavern-bottom-right">
          <img v-lazy="cloudTavernImg" alt="" srcset="">
        </div>
      </div>

    </div>


    <!-- CRM系统 -->
    <div class="caseCrm" id="crm">
      <img v-lazy="caseCrmImg" alt="" srcset="">
      <div class="caseCrm-text" style="margin-left:57PX">
        <span>CRM系统</span>
        
        <div class="caseCrm-line"></div>
      
        <div class="caseCrm-content">
          <div></div>
          <p>适用于各企业对客户数据的<br />统一管理</p>
        </div>

        <div class="caseCrm-content">
          <div></div>
          <p>全流程销售数据分析，轻松<br />掌控企业业绩</p>
        </div>

        <div class="caseCrm-content">
          <div></div>
          <p>多维度管理：系统管理、客户<br />管理、审批管理、业绩管理、<br />产品管理</p>
        </div>

      </div>
    </div>







    <div class="homeSolve">
      <div class="homeSolve-content">
        <div class="homeSolve-content-top">
          <p>提交需求获取解决方案</p>
          <p>欢迎咨询，提出您的需求，客服将尽心为您解答</p>

        </div>
        <div class="homeSolve-content-center">
          <div class="homeSolve-content-center-top">
            <input type="text" placeholder="请填写您的姓名（必填）" v-model="queryParams.name">
            <input type="text" placeholder="请填写您的联系方式（必填）" v-model="queryParams.phone">
          </div>
          <textarea name="" id="" placeholder="请描述一下您的需求" v-model="queryParams.demandDescribe"></textarea>
        </div>
        <div class="homeSolve-content-bottom">
          <button @click="messageLeave">提交</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
// @ is an alias to /src
import { messageLeave } from '@/api/index.js'
export default {
  name: 'Home',
  components: {
  },
  props: ['casePopName'],
  data() {
    return {
      topBg:require('../../assets/Web/case/topBg.png'),
      memberImg:require('../../assets/Web/case/member/img.png'),
      digitizationImg:require('../../assets/Web/case/digitization/img.png'),
      housekeepingImg:require('../../assets/Web/case/housekeeping/img.png'),
      unmannedImg1:require('../../assets/Web/case/unmanned/1.png'),
      unmannedImg2:require('../../assets/Web/case/unmanned/2.png'),
      cakeSubscribeImg: require('../../assets/Web/case/cakeSubscribe/example.png'),
      cloudTavernImg: require('../../assets/Web/case/cloudTavern/example.png'),
      caseCrmImg: require('../../assets/Web/case/crm/example.png'),
      
      one: true,//以此来控制每次轮播图切换的张数
      queryParams: {
        demandDescribe: '',
        name: '',
        phone: '',
      },
      memberList: [
        {
          title: '多种卡券',
          img: require('../../assets/Web/case/member/1.png'),
          content: '优惠券、集次卡、预存卡等，提升用户粘性',
        },
        {
          title: '积分兑换',
          img: require('../../assets/Web/case/member/2.png'),
          content: '积分可用于兑换卡券或抵充现金使用',
        },
        {
          title: '会员体系',
          img: require('../../assets/Web/case/member/3.png'),
          content: '包括会员管理、会员配置、会员等级管理、会员开卡赠礼等功能',
        },
        {
          title: '营销工具',
          img: require('../../assets/Web/case/member/4.png'),
          content: '支持卡券营销、积分兑换、会员等级权益、开卡赠礼等丰富的营销工具',
        },
      ],
      shoppingList: [
        {
          img: require('../../assets/Web/case/shopping/img.png'),
          bg: require('../../assets/Web/case/shopping/bg.png'),
          text: require('../../assets/Web/case/shopping/text.png'),
        },
        {
          img: require('../../assets/Web/case/shopping/img2.png'),
          bg: require('../../assets/Web/case/shopping/bg.png'),
          text: require('../../assets/Web/case/shopping/text2.png'),
        },
        {
          img: require('../../assets/Web/case/shopping/img.png'),
          bg: require('../../assets/Web/case/shopping/bg.png'),
          text: require('../../assets/Web/case/shopping/text.png'),
        },
        {
          img: require('../../assets/Web/case/shopping/img2.png'),
          bg: require('../../assets/Web/case/shopping/bg.png'),
          text: require('../../assets/Web/case/shopping/text2.png'),
        },
      ]
    }
  },
  watch: {
    casePopName: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.$nextTick(() => {
          document.querySelector('#' + newVal).scrollIntoView({
            block: 'center'
          });
        })
      },
    }
  },
  methods: {
    goWheel(event) {
      if (event.deltaY > 0 && this.one == true) {
        this.$refs.swiper.next();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
      }

      if (event.deltaY < 0 && this.one == true) {
        this.$refs.swiper.prev();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
      }
    },
    messageLeave() {
      messageLeave({
        demandDescribe: this.queryParams.demandDescribe,
        name: this.queryParams.name,
        phone: this.queryParams.phone,
      }).then(res => {
        if (res.code == 200) {
          this.queryParams.demandDescribe = ''
          this.queryParams.name = ''
          this.queryParams.phone = ''
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error('提交失败');
        }
      })
    },
  }
}
  </script>
  <style lang="scss" scoped>
.case {
  .caseTop {
    width: 100%;
    height: 452PX;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      z-index: -1;
      height: 452PX;
    }
    .text{
      p:first-child{
        font-weight: bold;
        font-size: 45.56PX;
        color: #fff;
        letter-spacing: 4PX;
        margin-bottom: 21PX;
      }
      p:last-child{
        font-family: SourceHanSansCN-Medium;
        font-size: 32.75PX;
        color: #fff;
        letter-spacing: 4PX;
      }
    }

  }
  .caseMember {
    margin-top: 63PX;
    margin-bottom: 61PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    .caseMember-top {
      text-align: center;
      margin-bottom: 59PX;
      p:first-child {
        color: #000000;
        font-size: 36PX;
        font-weight: bold;
        letter-spacing: 1PX;
      }
      p:last-child {
        margin-top: 20PX;
        color: #999999;
        font-size: 22PX;
        letter-spacing: 1PX;
      }
    }
    .caseMember-bottom {
      display: flex;
      .caseMember-bottom-left {
        margin-right: 77PX;
        img {
          width: 514PX;
          height:397PX;
        }
      }
      .caseMember-bottom-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .caseMember-bottom-right-list {
          display: flex;
          flex-direction: column;
          margin-bottom: 20PX;
          .caseMember-bottom-right-list-top {
            display: flex;
            margin-bottom: 3PX;
            align-items: center;
            p {
              color: #333333;
              font-size: 18PX;
              font-family: SourceHanSansCN-Medium;
            }
            img {
              width: 14PX;
              height: 14PX;
              margin-left: 5PX;
            }
          }
          .caseMember-bottom-right-list-bottom {
            display: flex;
            flex-direction: column;
            p {
              color: #c6c6c6;
              font-size: 16PX;
              line-height: 22PX;
            }
          }
        }
      }
    }
  }
  .caseDigitizationr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 476PX;;
    background-color: #F5F9FD;
    img {
      width: 359PX;
      height: 393PX;
      // height: 476PX;
    }
    .caseDigitizationr-line{
      width: 142.4PX;
      height: 2.8PX;
      background-color: #ABCAF2;
      border-radius: 1.42PX;
      margin-bottom: 27PX;
      margin-top: 17PX;
    }
    .caseDigitizationr-text{
      p:first-child{
        font-weight: bold;
        font-size: 36PX;
        color: #000000;
        line-height: 70PX;
      }
      p{
        letter-spacing: 0.5PX;
        font-size: 22PX;
        color: #999999;
        line-height: 35.59PX;
      }
    }
  }
  .caseShopping {
    margin-top: 57PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    .caseShopping-top {
      text-align: center;
      p:first-child {
        color: #000000;
        font-size: 36PX;
        font-weight: bold;
      }
      p:last-child {
        margin-top: 20PX;
        color: #999999;
        font-size: 22PX;
      }
    }
    .caseShopping-bottom {
      margin-top: 66PX;
      margin-bottom: 65PX;
      display: flex;
      width: 100%;
      height: 355PX;
      // padding-left: 339PX;
      // padding-right: 339PX;
      box-sizing: border-box;
      /deep/ .el-carousel,
      /deep/ .el-carousel__container {
        width: 100%;
        height: 100%;
       
      }
    
      /deep/ .el-carousel__item {
        display: flex;
        justify-content: center;
      }
      .caseShopping-bottom-left {
        img {
          width: 613PX;
          height: 355PX;
        }
      }

      .caseShopping-bottom-right {
        width: 270PX;
        height: 355PX;
        background-image: url("../../assets/Web/case/shopping/bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 195PX;
          height: 150PX;
        }
      }
    }
  }
  .caseUnmanned {
    margin-top: 56PX;
    margin-bottom: 91PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    .caseUnmanned-top {
      text-align: center;
      margin-bottom:63PX;
      p:first-child {
        color: #000000;
        font-size: 36PX;
        font-weight: bold;
      }
      p:last-child {
        margin-top: 20PX;
        color: #999999;
        font-size: 22PX;
      }
    }
    .caseUnmanned-bottom {
      display: flex;
      img {
        z-index: -1;
        top: 0;
        bottom: 0;
        position: absolute;
        width: 498.3PX;
        height: 412.9PX;
      }
      div:first-child {
        margin-right: 51PX;
        p:first-child{
        color: #CE9EFF;
      }
      p:nth-child(2){
        color: #CE9EFF;
      }
      }
      div{
      position: relative;
      width: 498.3PX;
      height: 412.9PX;
      p:first-child{
        color: #52A0EE;
        font-family:SourceHanSansCN-Medium ;
        font-size: 22PX;
        margin-top: 15PX;
        margin-left: 36PX;
      }
      p:nth-child(2){
        color: #8AC2FA;
        font-size: 13PX;
        margin-top: 4PX;
        margin-left: 36PX;
      }
     }
    }
  }

  
  .caseCakeSubscribe {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 476PX;;
    background-color: #F5F9FD;
    img {
      width: 359PX;
      height: 393PX;
      // height: 476PX;
    }
    .caseCakeSubscribe-line{
      width: 142.4PX;
      height: 2.8PX;
      background-color: #ABCAF2;
      border-radius: 1.42PX;
      margin-bottom: 27PX;
      margin-top: 17PX;
    }
    .caseCakeSubscribe-text{
      p:first-child{
        font-weight: bold;
        font-size: 36PX;
        color: #000000;
        line-height: 70PX;
      }
      p{
        letter-spacing: 0.5PX;
        font-size: 22PX;
        color: #999999;
        line-height: 35.59PX;
      }
    }
  }

  .caseCloudTavern {
    margin-top: 66PX;
    display: flex;
    flex-direction: column;
    align-items: center;

    .caseCloudTavern-top {
      text-align: center;
      margin-bottom:52PX;
      p:first-child {
        color: #000000;
        font-size: 36PX;
        font-weight: bold;
      }
      p:last-child {
        margin-top: 20PX;
        color: #999999;
        font-size: 22PX;
      }
    }

    .caseCloudTavern-bottom {

      display: flex;
      .caseCloudTavern-bottom-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .caseCloudTavern-bottom-left-content {
          margin-top: 37PX;
          // img {
          //   width: 25PX;
          //   height: 25PX;
          //   display: inline;
          //   vertical-align:middle;
          // }
          // p {
          //   font-size: 22PX;
          //   font-family: Source Han Sans CN;
          //   font-weight: 400;
          //   color: #000000;
          //   display: inline;
          //   vertical-align:middle;
          //   margin-left: 8PX;
          // }
          display: flex;
          .caseCloudTavern-bottom-content-img {
            width: 25PX;
            img {
              width: 25PX;
              height: 25PX;
              display: inline;
              vertical-align:middle;
            }
          }
          .caseCloudTavern-bottom-content-content {
            margin-left: 4px;
            margin-top: -1px;
            p {
              font-size: 22PX;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #000000;
              display: inline;
              vertical-align:middle;
            }
          }



        }
      }
      .caseCloudTavern-bottom-right {
        margin-left: 58PX;
        img {
          width: 697PX;
          height: 442PX;
        }
      }

    }
  }
  
  .caseCrm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 598PX;;
    background-color: #F5F9FD;
    img {
      width: 704PX;
      height: 471PX;
      // height: 476PX;
    }
    .caseCrm-line{
      width: 142.4PX;
      height: 2.8PX;
      background-color: #ABCAF2;
      border-radius: 1.42PX;
      margin-top: 15PX;
    }
    .caseCrm-text{
      span {
        margin-top: 146PX;
        font-weight: bold;
        font-size: 36PX;
        color: #000000;
      }

      .caseCrm-content {
        display: flex;
        align-items: center;
        p {
          letter-spacing: 0.5PX;
          font-size: 22PX;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          margin-top: 23PX;
        }

        div {
          width: 7PX;
          height: 7PX;
          background-color: #85C2FF;
          margin-right: 15PX;
          border-radius: 50%;
        }
      }
      
    }
  }


}
</style>
  